import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { AvailableIcon } from '../../helpers/icon-utils';

export const BundleFulfillmentMessageShipping = () => (
  <span data-component="FulfillmentPodShipping" className="sui-leading-tight">
    <div data-component="bundlePodMessageDelivery" className="sui-flex sui-mt-2">
      <div className="sui-pr-2 sui-mt-2">
        {AvailableIcon}
      </div>
      <p className="sui-font-regular sui-text-sm sui-tracking-normal sui-normal-case sui-line-clamp-unset sui-font-normal sui-text-primary">
        <div className="sui-pt-1">
          <span className="sui-font-bold">Delivery</span>
          <Typography variant="body-sm">
            <div data-testid="fulfillment-bundle-messsage">
              Details available in Cart
            </div>
          </Typography>
        </div>
      </p>
    </div>
  </span>
);
