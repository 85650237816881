import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  extend,
  hoist,
  number,
  params as gqlParams,
  QueryContext,
  QueryProvider,
  string
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { node } from 'prop-types';
import { PIPRedirector } from '@thd-nucleus/app-render';
import { Buybox } from '@thd-olt-component-react/buybox';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { Button } from '@one-thd/sui-atomic-components';
import { HdhomeSignup } from '@thd-olt-component-react/email-signup';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { MediaGalleryWithHotspotsCarousel } from '@thd-olt-component-react/media-gallery';
import { Metadata, ProductEntryMetadata } from '@thd-olt-component-react/metadata';
import { OrderSample, usePLPSamples } from '@thd-olt-component-react/order-sample';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { ProductDetails } from '@thd-olt-component-react/product-details';
import { Sharebelt, ShareFavorite } from '@thd-olt-component-react/thd-sharebelt';
import {
  parseUrl,
  productDefaultResultsDataModel,
  ProductResults,
  ProductResultsContext,
  ResultsApplied, ResultsDimensions,
  ResultsFilterAndSort, ResultsFilterAndSortDrawer,
  ResultsHeader,
  ResultsPagination,
  ResultsWrapped,
  updateQueryParams,
  useProductResults
} from '@thd-olt-component-react/product-results';
import {
  DynamicRecsWrapper,
  RecentlyViewedWrapper,
  InstantRecommendations
} from '@thd-olt-component-react/thd-recs-containers';
import { Link, useNavigate, useLocation, useParams } from '@thd-olt-component-react/router';
import { SearchFeedback } from '@thd-olt-component-react/search-feedback';
import { SponsoredCarousel } from '@thd-olt-component-react/sponsored-content';
import { SuperSku } from '@thd-olt-component-react/super-sku';
import { declareContexts, Hydrator } from '@thd-olt-component-react/hydrator';
import { ConnectedTranslatorWidget } from '@thd-olt-component-react/translator_widget';
import { CustomProductPodResponsive } from '../browse-search/CustomProductPodResponsive';
import { Breadcrumbs, BreadcrumbsLoad, BreadcrumbsModel } from './dynamic-imports';
import { pepCampaignId, subscriptionType, requestKey } from '../../constants';
import { shouldDisplaySearchFeedback, shouldDisplayResultsAppliedMobile, hasInStoreFilter } from './pep-utils';
import '../../styles/pep-page.scss';

const HoistedResultsWrapped = hoist(ResultsWrapped, CustomProductPodResponsive);
const HEADER_OFFSET = 59;
const CLIENT_SIDE_PRODUCT_POD_COUNT = 24;

const ZoneCard = ({ children }) => {
  return <div className="zone-card">{children}</div>;
};

ZoneCard.propTypes = {
  children: node.isRequired
};

export const PepMobile = () => {
  declareContexts([ProductResultsContext, QueryContext, ExperienceContext, CustomerContext]);
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const pageSize = 24;
  const ctx = useContext(ExperienceContext);
  const { channel, cookie, isConsumerApp } = ctx;
  const { itemId } = useParams();
  const { defaultVariables, isClientResolved } = useContext(QueryContext);
  const navigate = useNavigate();
  const { isLocalized, storeId, storeZip } = useStore({ varnish: false });
  const { search: querystring, pathname } = useLocation();
  const path = `${pathname}${querystring}`;
  const HYDRATION_STANDARD_SCROLL_BUFFER = 750;
  const isMobileOrConsumerApp = isConsumerApp || channel === 'mobile';
  const [sortValue, setSortValue] = useState('bestmatch:asc');
  let isTableView = false;

  let fsInteractiveRecs = useConfigService('fs:isInteractiveRecs');
  const viewPrimaryFilters = useConfigService('fs-prop:isviewPrimaryFiltersEnabled');
  const isGetItFastOnPlp = useConfigService('fs-prop:isGetItFastOnPlp');
  const isFulfillmentRedesignEnabled = useConfigService('fs-prop:isFulfillmentRedesignEnabled');

  const onStoreChange = ({ output }) => {
    const { storeId: localizedStoreId } = output || {};
    if (!localizedStoreId) return;

    if (window && window.THD_LOCALIZER_AUTO_INIT && window.THD_LOCALIZER_AUTO_INIT.Localizer) {
      window.THD_LOCALIZER_AUTO_INIT.Localizer.localize({ storeId: localizedStoreId }).then(() => {
        window.location.reload();
      });
    }
  };

  const makeStickyHeader = () => {
    if (channel === 'mobile') {
      const el = document.querySelector('.Header3');
      if (el) {
        el.classList.add('Header3-sticky');
      }
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-store', onStoreChange);
    makeStickyHeader();
  }, []);

  const onDimensionsChange = useCallback(({ refinement, dimension = '' }) => {
    const { url, dimensionName, clear = false, refinementKey } = refinement || {};
    const redirectUrl = dimensionName?.toUpperCase() === 'CATEGORY' ? url : null;
    const params = {
      ...parseUrl({ path }),
      ...parseUrl({ path: url }),
      Nao: 0
    };

    if (clear || (dimension && dimension?.label === 'Price' && !refinementKey)) {
      params.lowerbound = '';
      params.upperbound = '';
    }

    const paramsObject = Object.keys(params)
      .map((param) => {
        return {
          paramName: param,
          paramValue: params[param]
        };
      })
      .filter((par) => {
        return !/category|context|slugs/.test(par.paramName);
      });
    const updatedUrl = redirectUrl || updateQueryParams(path, paramsObject);
    window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements.click', refinement);
    navigate(updatedUrl);
  });

  const onChange = (dataSku = {}) => {
    const { slug, itemId: sskuItemId } = dataSku;
    if (slug && sskuItemId) {
      navigate(`/pep/${slug}/${sskuItemId}`);
    }
  };

  const scrollToRef = React.createRef();

  const targetPosition = (element) => {
    if (!element) return null;
    const currentPosition = window.scrollY || window.pageYOffset;
    return element.getBoundingClientRect().top + currentPosition;
  };

  const onPageChange = useCallback(({ page }) => {
    const newPath = updateQueryParams(path, [
      {
        paramName: 'Nao',
        paramValue: (page - 1) * pageSize
      }
    ]);

    navigate(newPath);

    const targetNode = scrollToRef && scrollToRef.current ? scrollToRef.current : null;
    let targetLocation = targetPosition(targetNode);
    window.scrollTo({
      top: targetLocation,
      behavior: 'smooth'
    });
  });

  const onSortChange = useCallback(({
    sortby, sortorder, name, currentPage, page
  }) => {
    const newPath = updateQueryParams(path, [
      {
        paramName: 'Nao',
        paramValue: null
      },
      {
        paramName: 'sortorder',
        paramValue: sortorder
      },
      {
        paramName: 'sortby',
        paramValue: sortby
      }
    ]);

    window.LIFE_CYCLE_EVENT_BUS.trigger('sort-refinements.click', {
      name,
      currentPage,
      page
    });
    navigate(newPath);
  });

  const handleProductClick = useCallback((evt, { canonicalUrl, target }) => {
    if (canonicalUrl) {
      navigate(canonicalUrl);
    }
  }, []);

  const {
    keyword,
    lowerbound,
    Nao = 0,
    sortby,
    nValue,
    sortorder,
    upperbound
  } = parseUrl({ path });

  const prcStoreId = useRef(storeId);
  const localizedAndClientLoaded = useRef(false);
  // show the sponsored products
  const additionalSearchParams = {
    sponsored: true,
    mcvisId: cookie?.adobeCookie?.MCMID
  };

  const {
    data, error, loading, responseLoading, variables
  } = useProductResults({
    additionalSearchParams,
    itemId,
    keyword,
    lowerbound,
    storeId,
    nValue,
    pageSize,
    start: Nao,
    sortby,
    sortorder,
    upperbound,
    isCustomerIdentified: isLocalized ? isCustomerIdentified : false
  });

  if (data && !loading) {
    prcStoreId.current = variables.storeId;
    localizedAndClientLoaded.current = true;
  }

  const { products = [] } = data?.searchModel || {};
  const pipPath = pathname && pathname.replace(/\/pep\//gi, '/p/');
  const isInStoreFilterON = hasInStoreFilter(data);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('pep.pip-ready', { itemId });
  }, [itemId]);

  const stringifyItemIds = (productsList) => {
    return productsList?.map(({ itemId: sku }) => sku).join('-');
  };

  useEffect(() => {
    if (data && !loading) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('pep.new-products', data?.searchModel);
    }
  }, [stringifyItemIds(products)]);

  const newDefaultVariables = {
    storeId,
    zipCode: storeZip,
    skipKPF: true,
    skipSubscribeAndSave: true,
    skipInstallServices: false,
    isBrandPricingPolicyCompliant: localizedAndClientLoaded.current ? isCustomerIdentified : false
  };

  const skipFn = ({ skip, queryName, attributes }) => {
    if (queryName !== 'product') {
      return skip;
    }
    const isResolved = isClientResolved({ queryName: 'searchModel' });
    if (attributes.product.fulfillment) {
      if (!isResolved) {
        return true;
      }
    }
    return skip;
  };
  const mountedFn = ({ mounted }) => {
    if (!isLocalized) return false;
    if (responseLoading) return false;
    return mounted;
  };
  let groupOfPods = 0;
  const maxNumOfPodsToHydrate = 6;
  let hydrators = products?.reduce((hydrator, product) => {
    if (!hydrator[groupOfPods]) {
      hydrator.push([]);
    }
    if (hydrator[groupOfPods].length < maxNumOfPodsToHydrate) {
      hydrator[groupOfPods].push(product);
      if (hydrator[groupOfPods].length === maxNumOfPodsToHydrate) {
        groupOfPods += 1;
      }
    }
    return hydrator;
  }, []);
  /*
    Get Sample Products
  */
  const { loading: samplesLoading } = usePLPSamples({
    products,
    productsFinishedLoading: pageSize === CLIENT_SIDE_PRODUCT_POD_COUNT
      && !responseLoading,
    storeId,
    storeZip
  });

  return (
    <Row className="isBound">
      <Col>
        <Row className="pep-page">
          <ErrorBoundary name="redirector">
            <PIPRedirector disableCanonicalRedirect disableCMMMCRedirect />
          </ErrorBoundary>
          <ErrorBoundary name="pep-metadata">
            <Metadata>
              <ProductEntryMetadata itemId={itemId} />
            </Metadata>
          </ErrorBoundary>
          <Row>
            <ErrorBoundary name="zone-a-row">
              <Col className="sui-pt-0">
                <span className="based-search sui-leading-none">Based on your search:</span>
              </Col>
              <Col className="sui-items-center sui-flex sui-justify-end sui-ml-auto sui-mr-4 sui-p-0">
                <ConnectedTranslatorWidget />
              </Col>
            </ErrorBoundary>
          </Row>
          <ErrorBoundary name="pep-zone_a">
            <div className="zone-a zone-a--mobile">
              <div className="zone-a__product zone-a__product--mobile">
                <Row>
                  <Col className="media-section" fallback="6" sm="6">
                    <ErrorBoundary name="media">
                      <Col className="sui-p-0">
                        <div className="product-details__favorites">
                          <Sharebelt itemId={itemId}>
                            <ShareFavorite showCircle showFavoritesCount />
                          </Sharebelt>
                        </div>
                        <MediaGalleryWithHotspotsCarousel
                          itemId={itemId}
                          firstItemFetchPriority="high"
                          showDynamicPagination
                          showLoader
                        />
                      </Col>
                    </ErrorBoundary>
                  </Col>
                  <Col className="info-section sui-pl-0" fallback="6" sm="3">
                    <ErrorBoundary name="info">
                      <Col className="sui-p-0">
                        <ErrorBoundary name="product-details">
                          <ProductDetails itemId={itemId}>
                            <div className="product-details__badge-title--wrapper">
                              <ProductDetails.Badge itemId={itemId} />
                              <ProductDetails.Title />
                            </div>
                            <ProductDetails.BrandCollection />
                            <ProductDetails.Ratings
                              pageType="PEP"
                              disableReviewsIfZero
                            />
                          </ProductDetails>
                        </ErrorBoundary>
                        <ErrorBoundary name="price">
                          <Price
                            disableRangePricing
                            displayEachUom={false}
                            itemId={itemId}
                            large={false}
                            skip
                          />
                        </ErrorBoundary>
                      </Col>
                    </ErrorBoundary>
                  </Col>
                </Row>
                <Col className="checkout-section" sm="3">
                  <ErrorBoundary name="check-out">
                    <Row>
                      <Col>
                        <ErrorBoundary name="super-sku">
                          <SuperSku hideCards itemId={itemId} onChange={onChange} />
                        </ErrorBoundary>
                        <ErrorBoundary name="order-sample">
                          <OrderSample itemId={itemId} link oosMessage>
                            Need a closer look?
                          </OrderSample>
                        </ErrorBoundary>
                        <Col>
                          <ErrorBoundary name="buybox">
                            <Row className="buybox-placeholder">
                              <Buybox hideInstantCheckout itemId={itemId} lite />
                            </Row>
                            <ErrorBoundary name="details">
                              <div className="see-details">
                                <Button
                                  fullWidth
                                  variant="secondary"
                                  href={pipPath}
                                  target="_blank"
                                >
                                  View Full Product Details
                                </Button>
                              </div>
                            </ErrorBoundary>
                          </ErrorBoundary>
                        </Col>
                      </Col>
                    </Row>
                  </ErrorBoundary>
                </Col>
              </div>
            </div>
          </ErrorBoundary>

          {fsInteractiveRecs && (
            <InstantRecommendations
              errorBoundary
              hydrator={{
                className: 'grid',
                scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER,
                delay: 0
              }}
              anchorId={itemId}
              schemaName="instantrecomm"
              requestKey={requestKey}
            />
          )}

          <DynamicRecsWrapper
            schemaName="pipsem"
            anchorId={itemId}
            hideATC
            requestKey={requestKey}
            errorBoundary
            hydrator={{
              className: 'grid',
              tag: ZoneCard,
              id: `pipsem-${itemId}`,
              delay: 200,
              scrollBuffer: 0
            }}
          />
          <Col>
            <ErrorBoundary name="breadcrumbs">
              <Hydrator
                id="pep-mobile-breadcrumbs"
                scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                waitFor={[BreadcrumbsLoad]}
              >
                <Breadcrumbs data={data} error={error} loading={loading} />
              </Hydrator>
            </ErrorBoundary>
          </Col>
          <Row>
            <ErrorBoundary name="product-results-group">
              <Col>
                <span ref={scrollToRef} />
                <ProductResults data={data} error={error} loading={loading}>
                  <Row>
                    <ErrorBoundary name="results-header" useContextForProps>
                      <div className="sui-h1-display sui-leading-none sui-uppercase
                    sui-line-clamp-unset sui-font-normal sui-text-primary sui-pl-3"
                      >
                        Similar
                      </div>
                      <Col className="sui-pt-0">
                        <ResultsHeader />
                      </Col>
                    </ErrorBoundary>
                  </Row>
                  <Row>

                    {!viewPrimaryFilters && isGetItFastOnPlp && (
                      <Row>
                        <ErrorBoundary name="results-applied">
                          <Col sm="9">
                            <ResultsApplied
                              onAppliedChange={onDimensionsChange}
                              isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                              isGetItFastOnPlp={isGetItFastOnPlp}
                            />
                          </Col>
                        </ErrorBoundary>
                      </Row>
                    )}
                    {viewPrimaryFilters && isGetItFastOnPlp && (
                      <>
                        <Row>
                          <ErrorBoundary name="results-applied">
                            <Col sm="9">
                              <ResultsApplied
                                onAppliedChange={onDimensionsChange}
                                viewPrimaryFilters={viewPrimaryFilters}
                                isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                isGetItFastOnPlp={isGetItFastOnPlp}
                              />
                            </Col>
                          </ErrorBoundary>
                        </Row>
                      </>
                    )}
                    <Row>
                      {!viewPrimaryFilters && (
                        <ErrorBoundary name="results-filter-and-sort">
                          <Col>
                            <ErrorBoundary name="mb-filter-and-sort">
                              <ResultsFilterAndSort
                                onSortChange={onSortChange}
                                onDimensionsChange={onDimensionsChange}
                                stickyOffset={HEADER_OFFSET}
                                enableMultiStore={!isConsumerApp}
                                disableStickyFilter
                                excludePickUpToday
                                isTableView={isTableView}
                                isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                              />
                            </ErrorBoundary>
                          </Col>
                        </ErrorBoundary>
                      )}
                      {viewPrimaryFilters && (
                        <ErrorBoundary name="results-filter-and-sort-drawer">
                          <Col className="primary-filters-container--no-padding-right">
                            <ErrorBoundary name="mb-filter-and-sort-drawer">
                              <ResultsFilterAndSortDrawer
                                onSortChange={onSortChange}
                                onDimensionsChange={onDimensionsChange}
                                stickyOffset={HEADER_OFFSET}
                                enableMultiStore={!isConsumerApp}
                                disableStickyFilter
                                excludePickUpToday
                                isTableView={isTableView}
                                hideViewAll={!viewPrimaryFilters}
                                isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                viewPrimaryFilters={viewPrimaryFilters}
                                isGetItFastOnPlp={isGetItFastOnPlp}
                              />
                            </ErrorBoundary>
                          </Col>
                        </ErrorBoundary>
                      )}
                    </Row>
                  </Row>
                  { (isFulfillmentRedesignEnabled || isGetItFastOnPlp) && (
                    <Row>
                      <Col className="browse-search--no-right-padding">
                        <ResultsDimensions
                          onDimensionsChange={onDimensionsChange}
                          showOnlyGetItFast
                          isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                          viewPrimaryFilters={viewPrimaryFilters}
                          isGetItFastOnPlp={isGetItFastOnPlp}
                        />
                      </Col>
                    </Row>
                  )}
                  {viewPrimaryFilters && (
                    <>
                      {!isGetItFastOnPlp && (
                        <Row>
                          <ErrorBoundary name="results-applied">
                            <Col flatten>
                              <Col sm="9" className="sui-pl-0">
                                <ResultsApplied
                                  onAppliedChange={onDimensionsChange}
                                  viewPrimaryFilters={viewPrimaryFilters}
                                  isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                  isGetItFastOnPlp={isGetItFastOnPlp}
                                />
                              </Col>
                            </Col>
                          </ErrorBoundary>
                        </Row>
                      )}
                    </>
                  )}

                  <Row className="u--paddingBottom flush">
                    <ErrorBoundary name="results-dimensions">
                      <Col>
                        <Col>
                          <ErrorBoundary name="wrapped-results">
                            <Row>
                              <Col nopadding>
                                {hydrators?.length && (
                                  <QueryProvider
                                    cacheKey="pep-mobile-product-pods"
                                    dataSource="searchNav"
                                    defaultVariables={newDefaultVariables}
                                    mounted={mountedFn}
                                    skip={skipFn}
                                  >
                                    {hydrators?.map((group, hydratorIndex) => {
                                      if (!group?.length) return null;
                                      return (
                                        <Hydrator
                                          className="grid"
                                          id={`pep-mobile-pods-${hydratorIndex + 1}`}
                                          key={`pep-hydrator-${hydratorIndex}`}
                                          patch={!hydratorIndex}
                                          scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                                        >
                                          <HoistedResultsWrapped>
                                            <div className="grid">
                                              {group?.map((product, itemIndex) => {
                                                const { itemId: skuId, canonicalUrl } = product?.identifiers || {};
                                                if (!skuId) return null;
                                                const podPosition = hydratorIndex * maxNumOfPodsToHydrate + itemIndex;
                                                return (
                                                  <ErrorBoundary
                                                    id={`pep-product-pod__${itemIndex}`}
                                                    index={itemIndex}
                                                    key={`${skuId}-${itemIndex}`}
                                                    name={`Product Pod: ${itemIndex}`}
                                                    className="pep-pod__left-outer-padding"
                                                  >
                                                    <Col
                                                      className="pep__pod"
                                                      fallback="12"
                                                      lg="3"
                                                      md="3"
                                                      sm="3"
                                                      xs="12"
                                                    >
                                                      <CustomProductPodResponsive
                                                        parent="pep"
                                                        itemId={skuId}
                                                        url={canonicalUrl}
                                                        storeId={prcStoreId.current}
                                                        onHover={() => {}}
                                                        hoverDelay={0}
                                                        position={podPosition}
                                                        itemRecsIndex={itemIndex}
                                                        hasInStoreFilter={isInStoreFilterON}
                                                        onClick={handleProductClick}
                                                        samplesLoading={samplesLoading}
                                                        isMobileOrConsumerApp={isMobileOrConsumerApp}
                                                      />
                                                    </Col>
                                                  </ErrorBoundary>
                                                );
                                              })}
                                            </div>
                                          </HoistedResultsWrapped>
                                        </Hydrator>
                                      );
                                    })}
                                  </QueryProvider>
                                )}
                              </Col>
                            </Row>
                            <Row className="u--paddingTop u--paddingBottom">
                              <Col nopadding>
                                <Col>
                                  <ErrorBoundary name="results-pagination">
                                    <ResultsPagination
                                      onPageChange={onPageChange}
                                      pageSize={pageSize}
                                    />
                                  </ErrorBoundary>
                                </Col>
                              </Col>
                            </Row>
                          </ErrorBoundary>
                        </Col>
                        <Row>
                          <ErrorBoundary name="promotional">
                            <HdhomeSignup
                              formSubmission
                              subscriptionType={subscriptionType}
                              campaignId={pepCampaignId}
                            />
                          </ErrorBoundary>
                        </Row>
                        <SponsoredCarousel
                          breadCrumbs={data?.searchModel?.taxonomy?.breadCrumbs}
                          pageContext={{
                            schema: 'pip_sponsored',
                            data: { itemId },
                            isPip: true
                          }}
                          showSponsoredCarousel
                          dynamic={{
                            pageType: 'pip'
                          }}
                          hydrator={{
                            className: 'grid',
                            tag: ZoneCard,
                            scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                          }}
                        />
                        <QueryProvider cacheKey="rv-pep-recs" persist defaultVariables={newDefaultVariables}>
                          <RecentlyViewedWrapper
                            schemaName="rv_gm_pip_rr"
                            errorBoundary
                            hydrator={{
                              className: 'grid',
                              tag: ZoneCard,
                              id: 'rv_gm_pip_rr',
                              scrollBuffer: 1500,
                              delay: 3000
                            }}
                          />
                        </QueryProvider>
                        {shouldDisplaySearchFeedback(ctx) && (
                          <Row className="u--paddingTop u--paddingBottom">
                            <ErrorBoundary name="search-feedback">
                              <Col>
                                <SearchFeedback />
                              </Col>
                            </ErrorBoundary>
                          </Row>
                        )}
                      </Col>
                    </ErrorBoundary>
                  </Row>
                </ProductResults>
              </Col>
            </ErrorBoundary>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

PepMobile.displayName = 'PepPage';

PepMobile.propTypes = {};

PepMobile.defaultProps = {};

const ProductSearchDataModel = {
  searchModel: gqlParams({ keyword: string(), navParam: string() }).shape({
    products: gqlParams({ startIndex: number() }).arrayOf(CustomProductPodResponsive.dataModel.product)
  })
};

PepMobile.dataModel = extend(
  PIPRedirector,
  BreadcrumbsModel,
  Buybox,
  HdhomeSignup,
  MediaGalleryWithHotspotsCarousel,
  OrderSample,
  Price,
  PriceClearanceDataModel,
  ProductDetails,
  ResultsFilterAndSort,
  Sharebelt,
  SuperSku,
  productDefaultResultsDataModel,
  DynamicRecsWrapper,
  RecentlyViewedWrapper,
  ProductSearchDataModel,
  InstantRecommendations
);
