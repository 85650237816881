import React, {
  useContext, useEffect, useState, useRef, Suspense
} from 'react';
import {
  bool, string, oneOf, func, shape
} from 'prop-types';
import classNames from 'classnames';
import { Image, Carousel } from '@thd-olt-component-react/core-ui';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { useLazyDataModel, useDataModel } from '@thd-nucleus/data-sources';
import { PaintSwatches } from '@one-thd/sui-icons';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import {
  BackgroundCircleIcon, ThreeSixtyIcon, ARIcon, HotspotTagIcon, PlayIcon
} from '../Icons';
import { componentReady, mediaClick, thumbnailClick } from '../../../publisher';
import { MediaGalleryLoader } from '../MediaGalleryLoader/MediaGalleryLoader';
import Helper from '../../Helper';
import './media-gallery-carousel.style.scss';
import { dataModel } from '../../dataModel';
import { useDecisionEngine } from '../../../hooks/DecisionEngine/useDecisionEngine';

const MediaGalleryOverlay = React.lazy(() => import(
  /* webpackChunkName: "media-gallery-overlay" */'../MediaGalleryOverlay'
));
const MediaGalleryOverlayMobile = React.lazy(() => import(
  /* webpackChunkName: "media-gallery-overlay-mobile" */'../MediaGalleryOverlayMobile'
));
const FilmstripMobile = React.lazy(() => import(
  /* webpackChunkName: "media-gallery-filmstrip-mobile" */'../FilmstripMobile'
));

export const MediaGalleryCarousel = ({
  itemId, hover, overlayType, showDynamicPagination, firstItemFetchPriority, showLoader,
  configId, onMediaClick, overrideData, pageType
}) => {
  const experienceContext = useContext(ExperienceContext);
  const storeId = useStoreId();
  const carouselWrapperRef = useRef();
  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';

  const [mainAsset, setMainAsset] = useState(null);
  const [showAvataarAR, setShowAvataarAR] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [filmstripIsOpen, setFilmstripIsOpen] = useState(false);
  const [superSkuHoverId, setSuperSkuHoverId] = useState('');
  const [openTab, setOpenTab] = useState('Images');
  const [openImage, setOpenImage] = useState(null);
  const showAvataarDefault = Helper.containAvataarId(itemId);
  useEffect(() => {
    if (showAvataarDefault) {
      Helper.loadAvataarScript();
    }
  }, [showAvataarDefault]);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('media-gallery.avataar.click', (payload) => {
      const avataarAR = payload?.output?.showAvataar;
      setShowAvataarAR(avataarAR);
      setFilmstripIsOpen(avataarAR);
      if (avataarAR) {
        setOpenTab('3D');
      }
    });
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('media-gallery.avataar.click');
    };
  }, []);

  useEffect(() => {
    if (hover) {
      window.LIFE_CYCLE_EVENT_BUS.on('supersku.hover', (hoverItemId) => {
        setSuperSkuHoverId(hoverItemId?.output);
      });
    }
  }, [hover]);

  useEffect(() => {
    setFilmstripIsOpen(!!showAvataarAR);
    setOpenTab('3D');
  }, [showAvataarAR]);
  const [superSkuHoveredProductRequest, superSkuHoveredProductResponse] = useLazyDataModel('product', {});

  useEffect(() => {
    if (superSkuHoverId) {
      superSkuHoveredProductRequest({
        variables: {
          itemId: superSkuHoverId,
          storeId,
          configId,
          skipPaintDetails: !configId
        }
      });
    }
  }, [superSkuHoverId]);

  useEffect(() => {
    componentReady({ itemId });
  }, [itemId]);

  // for swatch Hover
  const mainSuperSkuHover = Helper.getMainSuperSkuHover({
    itemId, superSkuHoveredProductResponse, showAvataarDefault, superSkuHoverId
  });
  const hasItemId = superSkuHoverId || itemId;
  const productData = useDataModel('product', {
    variables: {
      itemId,
      configId,
      skipPaintDetails: !configId
    },
    skip: !hasItemId
  });

  const reviewPhotos = useDataModel('reviewPhotos', {
    variables: { itemId: itemId || '' },
    skip: !itemId
  });

  const {
    externalMedia
  } = useLifeCycleEventBus('configurator.configurator_media_gallery_event');

  const { product = {} } = productData.data || {};
  const { brandName, productLabel } = product.identifiers || {};

  const decisionEngineResponse = useDecisionEngine({ media: product?.media, isMobile, pageType });
  const useFilmstripMobile = overlayType === 'filmstrip' && isMobile;

  const { displayedMedia, ...apiResults } = Helper.getDisplayedMedia({
    decisionEngineResponse,
    isMobile,
    itemId,
    productData,
    externalMedia,
    overrideData,
    placeholder: showLoader && <MediaGalleryLoader isMobile isCarousel />,
    showAvataarDefault,
    pageType
  });

  useEffect(() => {
    const filtered = displayedMedia.main;
    const augmentedRealityIndex = displayedMedia.augmentedRealityIndex;

    let selectedMedia;
    if (filtered) {
      selectedMedia = filtered[showAvataarAR ? augmentedRealityIndex : selectedImageIndex];
    }

    let selectedTab;
    if (Helper.isImage({ media: selectedMedia })
      || Helper.isCustomColor({ media: selectedMedia })
      || Helper.isManualColor({ media: selectedMedia })
      || Helper.isHotspot({ media: selectedMedia })
    ) {
      selectedTab = 'Images';
    }
    if (Helper.isVideo({ media: selectedMedia })) {
      selectedTab = 'Videos';
    }
    if (Helper.isImage360({ media: selectedMedia })) {
      selectedTab = '360° View';
    }
    if (Helper.isAR({ media: selectedMedia }) && selectedMedia?.usdz.length === 0) {
      selectedTab = '3D';
    }

    if (selectedTab) {
      setOpenTab(selectedTab);
    }
  }, [selectedImageIndex, displayedMedia]);

  if (apiResults.apiState) {
    return apiResults.returnStatement;
  }
  const { thumbnails, main, zoom } = displayedMedia;

  const carouselItemClassName = classNames(
    'media-gallery-carousel', {
      'filmstrip-mobile-carousel': useFilmstripMobile,
      'mobile-carousel': channel === 'mobile'
    }
  );

  const handleImageClick = (media, mediaIndex) => {
    if (useFilmstripMobile) {
      if (Helper.isImage({ media }) || Helper.isHotspot({ media })) {
        setOpenImage(media.url);
      }
      if (Helper.isVideo({ media })) {
        setOpenImage(media.videoStill);
      }
      if (Helper.isCustomColor({ media }) || Helper.isManualColor({ media })) {
        setOpenImage(media.id);
      }
      setFilmstripIsOpen(true);
    }

    // analytics
    // Trigger overlay open
    thumbnailClick({ primaryCategory: 'overlay', eventName: 'expanded-view' });
    // Image Info
    mediaClick({
      section: 'expanded-view',
      component: 'thumbnails',
      target: Helper.getMediaType(media),
      position: (mediaIndex + 1) || 'n/a',
      guid: Helper.getMediaType(media) === 'image' ? Helper.getImageGuid(media) : 'n/a'
    });
    if (typeof window !== 'undefined') {
      window.history.pushState({ page: 'MEDIAOVERLAY' }, null, '');
    }

    setMainAsset(media);

    if (Helper.isInternalAR(media) && onMediaClick) {
      onMediaClick({ media });
    }
  };

  const onChangeImage = () => {
    setTimeout(() => {
      const slides = carouselWrapperRef.current.querySelectorAll('.swiper-slide');
      const activeSlide = carouselWrapperRef.current.querySelector('.swiper-slide-active');
      const activeIndex = Array(...slides).indexOf(activeSlide);
      setSelectedImageIndex(activeIndex);
    }, 1);
  };

  const hideOverlay = () => {
    setMainAsset(null);
    setShowAvataarAR(false);
  };

  return (
    <div
      className={carouselItemClassName}
      ref={carouselWrapperRef}
      data-component="MediaGalleryCarousel"
    >
      <Carousel
        pagination
        dynamicPagination={useFilmstripMobile || showDynamicPagination}
        peek={false}
        breakpoints={{
          sm: {
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          md: {
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          lg: {
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          xl: {
            slidesPerView: 1,
            slidesPerGroup: 1
          }
        }}
        slidesPerView={1}
        onSlideChange={() => onChangeImage()}
      >
        {
          displayedMedia.main
            .map((media, mediaIndex) => {
              const {
                shortDescription,
                uploadDate,
                dateModified,
                url,
                mediaStill,
                videoStill,
                altText,
                rgb
              } = media;
              const isVideo = Helper.isVideo({ media });
              const isThreeSixty = Helper.isImage360({ media });
              const isImage = Helper.isImage({ media });
              const isAR = Helper.isAR({ media });
              const isAvataarAR = Helper.isAvataarAR({ media });
              const isHotspot = Helper.isHotspot({ media });
              const isCustomColor = Helper.isCustomColor({ media });
              const isManualColor = Helper.isManualColor({ media });
              let imageUrl = url;
              let index = 0;
              const alt = altText || `${brandName} - ${productLabel}`;

              if (mediaIndex === selectedImageIndex) {
                if (isImage) {
                  index = mainSuperSkuHover.findIndex((superSkuHoverMedia) => {
                    return superSkuHoverMedia.type === main[selectedImageIndex].type;
                  });
                  if (index > 0 && mainSuperSkuHover[index]) {
                    imageUrl = mainSuperSkuHover[index].url;
                  }
                  if (index <= 0 && mainSuperSkuHover[0]) {
                    imageUrl = mainSuperSkuHover[0].url;
                  }
                } else if (isVideo) {
                  imageUrl = mainSuperSkuHover[0] ? mainSuperSkuHover[0].url : videoStill;
                } else if (isThreeSixty || isAR) {
                  imageUrl = mainSuperSkuHover[0] ? mainSuperSkuHover[0].url : mediaStill;
                }
              } else if (isVideo) {
                imageUrl = videoStill;
              } else if (isThreeSixty || isAR) {
                imageUrl = mediaStill;
              }

              const videoScriptContent = JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'VideoObject',
                name: productLabel,
                description: shortDescription,
                thumbnailUrl: [videoStill],
                contentUrl: url,
                uploadDate,
                dateModified
              });

              return (
                <div
                  data-testid="carousel-slide"
                  className={classNames('media-gallery-carousel__slide', {
                    'active-slide': mediaIndex === selectedImageIndex
                  })}
                  key={mediaIndex}
                >
                  {isHotspot && (
                    <div className="hotspot-container" data-testid="hotspot-tag-icon">
                      <HotspotTagIcon
                        mediaGalleryIndex={mediaIndex}
                        openOverlay
                        hotspots={media?.hotspots}
                        ocrHotspots={media?.ocrHotspots}
                        imageUrl={imageUrl}
                        onHotspotImageClick={() => handleImageClick(media, mediaIndex)}
                      />
                    </div>
                  )}
                  {!isHotspot && (
                    <>
                      {isCustomColor || isManualColor
                        ? (
                          <>
                            {isManualColor
                              ? (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                <div
                                  role="button"
                                  tabIndex={-1}
                                  className={classNames('manualSwatch',
                                    'sui-aspect-square',
                                    'sui-bg-inactive',
                                    'sui-flex',
                                    'sui-items-center',
                                    'sui-justify-center')}
                                  onClick={() => handleImageClick(media, mediaIndex)}
                                  data-testid="rgb-div"
                                >
                                  <div className="sui-text-center sui-flex sui-items-center sui-justify-center">
                                    <div style={{ height: 'auto' }}>
                                      <PaintSwatches color="medium" />
                                      <div>No Color Preview Available</div>
                                    </div>
                                  </div>
                                  <div
                                    className="media-gallery-carousel__paint-reorder-color sui-absolute"
                                  >
                                    <Image
                                      alt={alt}
                                      src={media.brandLogo}
                                    />
                                  </div>
                                </div>
                              )
                              : (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                <div
                                  role="button"
                                  tabIndex={-1}
                                  className="media-gallery-carousel__paint-reorder-color"
                                  onClick={() => handleImageClick(media, mediaIndex)}
                                  data-testid="rgb-div"
                                  style={{
                                    backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
                                  }}
                                >
                                  <Image
                                    alt={alt}
                                    src={media.brandLogo}
                                  />
                                </div>
                              )}
                          </>
                        )
                        : (
                          <>
                            <Image
                              className={classNames({
                                'video-thumbnail': isVideo,
                                image: isImage,
                                hotspot: isHotspot,
                                ar: isAR,
                                'three-sixty': isThreeSixty
                              })}
                              onClick={() => handleImageClick(media, mediaIndex)}
                              alt={alt}
                              src={imageUrl}
                              fetchpriority={mediaIndex === 0 ? firstItemFetchPriority : null}
                              lazy={mediaIndex >= 1}
                              height={600}
                              width={600}
                            />
                            {!isImage
                              && (!mainSuperSkuHover[0] || mediaIndex !== selectedImageIndex)
                              && (
                                <button
                                  type="button"
                                  onClick={() => handleImageClick(media, mediaIndex)}
                                >
                                  <BackgroundCircleIcon />
                                  {isThreeSixty && <ThreeSixtyIcon />}
                                  {isAR && isAvataarAR && <ThreeSixtyIcon />}
                                  {isAR && !isAvataarAR && <ARIcon />}
                                  {isHotspot && <HotspotTagIcon />}
                                  {isVideo
                                    && (
                                      <>
                                        <PlayIcon />
                                        <script
                                          type="application/ld+json"
                                          /* eslint-disable-next-line react/no-danger */
                                          dangerouslySetInnerHTML={{ __html: videoScriptContent }}
                                        />
                                      </>
                                    )}
                                </button>
                              )}
                          </>
                        )}
                    </>
                  )}
                </div>
              );
            })
        }
      </Carousel>
      {((!!mainAsset || showAvataarAR) && !Helper.isInternalAR(mainAsset))
        && (
          <Suspense fallback={(<div />)}>
            {!useFilmstripMobile
              && ((!isMobile
                  && (
                    <MediaGalleryOverlay
                      handleClose={hideOverlay}
                      overlayOpened={!!mainAsset}
                      media={displayedMedia}
                      product={product}
                      mainAsset={mainAsset}
                      thumbnails={thumbnails}
                      itemId={itemId}
                      showAvataarDefault={showAvataarDefault}
                      showAvataarAR={showAvataarAR}
                    />
                  ))
                || (isMobile
                  && (
                    <MediaGalleryOverlayMobile
                      handleClose={hideOverlay}
                      overlayOpened={!!mainAsset}
                      itemId={itemId}
                      product={product}
                      mainImage={mainAsset || main[showAvataarAR ? displayedMedia?.augmentedRealityIndex : 0]}
                      mainImages={main}
                      onMediaClick={handleImageClick}
                      zoomedImages={zoom}
                      thumbnails={thumbnails}
                      selectedImageIndex={selectedImageIndex}
                      showAvataarDefault={showAvataarDefault}
                      showAvataarAR={showAvataarAR}
                    />
                  )
                ))}
            {useFilmstripMobile
              && (
                <>
                  <FilmstripMobile
                    data={{ displayedMedia, brandName, galleryLabel: productLabel }}
                    isMobile
                    itemId={itemId}
                    isOpen={filmstripIsOpen}
                    setIsOpen={setFilmstripIsOpen}
                    openImage={openImage}
                    setOpenImage={setOpenImage}
                    selectedTab={openTab}
                    showAvataarDefault={showAvataarDefault}
                    showAvataarAR={showAvataarAR}
                    onAvataarButtonClick={() => setShowAvataarAR(false)}
                    reviewPhotos={reviewPhotos}
                  />
                </>
              )}
          </Suspense>
        )}
    </div>
  );
};

MediaGalleryCarousel.displayName = 'MediaGalleryCarousel';

MediaGalleryCarousel.propTypes = {
  hover: bool,
  itemId: string.isRequired,
  configId: string,
  overlayType: oneOf([
    'default',
    'filmstrip' // filmstrip is currently only UX-approved for mobile
  ]),
  showDynamicPagination: bool,
  firstItemFetchPriority: oneOf([
    'high', // Fetch the image at a high priority relative to other images.
    'low', // Fetch the image at a low priority relative to other images.
    'auto' // Indicates no preference for the fetch priority. The browser decides what is best for the user.
  ]),
  showLoader: bool,
  onMediaClick: func,
  overrideData: shape({
    media: shape({}),
    galleryLabel: string,
    brandNameLabel: string
  }),
  pageType: string
};

MediaGalleryCarousel.defaultProps = {
  hover: false,
  overlayType: 'default',
  showDynamicPagination: false,
  firstItemFetchPriority: 'auto',
  showLoader: false,
  configId: undefined,
  onMediaClick: null,
  overrideData: null,
  pageType: ''
};

MediaGalleryCarousel.dataModel = dataModel;