import React from 'react';
import {
  shape, bool, number, object, string
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { TileHead } from '../TileHead';
import { TileFooter } from '../TileFooter';
import { formatDate, formatInventory, showTodayOrTomorrow } from '../../../helper/utils';
import { getDefaultStoreHours, showBopisDate } from '../../../helper/timeZoneUtils';
import { IDS } from '../../../../automationTestIds';

const DefaultBOPIS = ({
  localStoreLocation, pickupStoreHours, isCartTiles, fallbackMode,
  configuratorHideQuantity, deliveryTimeline
}) => {
  let { storeHours } = useStore();
  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const isInStock = localStoreLocation?.inventory?.isInStock || '';
  const quantity = localStoreLocation?.inventory?.quantity;
  const formattedInventoryQty = formatInventory(quantity);
  const pickupStoreHoursWithTZ = isCartTiles ? pickupStoreHours
    : { ...storeHours, ...pickupStoreHours };
  const isZeroInStock = isCartTiles && formattedInventoryQty <= 0;
  return (
    <div className="fulfillment-tile">
      <TileHead title="Pickup" automationId={IDS.LABEL.pickupLabel} />
      <div className="fulfillment__sub-title">
        {isInStock && (
          <>
            <div
              className="u__bold pickup-timeline"
              data-testid="pickupTimeline"
              data-automation-id={IDS.ETA.pickupETA}
            >
              {deliveryTimeline ? showTodayOrTomorrow(deliveryTimeline, true, deliveryTimeline, formatDate(deliveryTimeline)) : showBopisDate(pickupStoreHoursWithTZ)}
            </div>
            {
              !fallbackMode && !configuratorHideQuantity
              && (
                <div
                  className={`fulfillment-qty-row ${isZeroInStock ? 'u__text--error' : ''} `}
                  data-automation-id={IDS.QTY.pickupQty}
                >
                  <span className={`u__text--success ${isZeroInStock ? 'u__text--error' : ''}`}>
                    {formattedInventoryQty}
                  </span> in stock
                </div>
              )
            }
          </>
        )}
      </div>
      <TileFooter className="u__bold">
        <span className="u__text--success">FREE</span>
      </TileFooter>
    </div>
  );
};

DefaultBOPIS.propTypes = {
  localStoreLocation: shape({
    isAnchor: bool,
    inventory: shape({
      quantity: number,
      isInStock: bool,
      isLimitedQuantity: bool,
      isBackordered: bool
    })
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  isCartTiles: bool,
  fallbackMode: bool,
  configuratorHideQuantity: bool,
  deliveryTimeline: string
};

DefaultBOPIS.defaultProps = {
  isCartTiles: false,
  pickupStoreHours: null,
  fallbackMode: false,
  configuratorHideQuantity: false,
  deliveryTimeline: null
};

export { DefaultBOPIS };
