export const FEATURE_SWITCH_KEYS = Object.freeze({
  bogo: 'fs-prop:buy-area__promotion-products--bogoEnabled',
  msb: 'fs-prop:buy-area__promotion-products--msbEnabled',
  bmsm: 'fs-prop:buy-area__promotion-products--bmsmEnabled',
  backwardsBogo: 'fs-prop:buy-area__promotion-products--backwardsBogoEnabled',
  bogoDollarThreshold: 'fs:promotion-products--bogoDollarThreshold',
  rewardDrawerInitialLength: 'fs:promotion-products--rewardDrawerInitialLength',
  rewardDrawerMaxLength: 'fs:promotion-products--rewardDrawerMaxLength',
  showProgressOnLoad: 'fs:promotion-products--showProgressOnLoad',
  bxg1Enabled: 'fs:promotion-products--bxg1Enabled', // clean up
  categorizedPromotionEnabled: 'fs:promotion-products--categorizedPromotionEnabled',
  enableCartGraphQL: 'fs:promotion-products--enableCartGraphQL',
  enableLocalizedPromotion: 'fs:promotion-products--enableLocalizedPromotion',
});

export const EXPERIENCE_TAGS = Object.freeze({
  BOGO: 'BOGO',
  BMSM: 'BMSM',
  MSB: 'MSBONLINE'
});

export const FORWARDS_BOGO_SUBEXPERIENCE_TAGS = Object.freeze({
  buyOneGetOne: 'buyOneGetOne',
  buyOneGetPercentageOff: 'buyOneGetPercentageOff',
  buyOneGetDollarOff: 'buyOneGetDollarOff',
  buyOneGetY: 'buyOneGetY',
  buyOneGetYPercentageOff: 'buyOneGetYPercentageOff',
  buyOneGetYDollarOff: 'buyOneGetYDollarOff',
  buyXGetOne: 'buyXGetOne',
  buyXGetOnePercentageOff: 'buyXGetOnePercentageOff',
  buyXGetOneDollarOff: 'buyXGetOneDollarOff',
  buyXGetY: 'buyXGetY',
  buyXGetYPercentageOff: 'buyXGetYPercentageOff',
  buyXGetYDollarOff: 'buyXGetYDollarOff',
});

export const FORWARDS_BOGO_SUBEXPERIENCES = Object.freeze([
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff,
]);

export const FORWARDS_B1GY_SUBEXPERIENCES = Object.freeze([
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetY,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYDollarOff,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetYPercentageOff,
]);
export const FORWARDS_BXG1_SUBEXPERIENCES = Object.freeze([
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOne,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOnePercentageOff,
  FORWARDS_BOGO_SUBEXPERIENCE_TAGS.buyXGetOneDollarOff,
]);
export const BACKWARDS_BOGO_SUBEXPERIENCE_TAGS = Object.freeze({
  buyOneGetOne: 'buyOneGetOneBackwards',
  buyOneGetPercentageOff: 'buyOneGetPercentageOffBackwards',
  buyOneGetDollarOff: 'buyOneGetDollarOffBackwards',
  buyOneGetY: 'buyOneGetYBackwards',
  buyOneGetYPercentageOff: 'buyOneGetYPercentageOffBackwards',
  buyOneGetYDollarOff: 'buyOneGetYDollarOffBackwards',
  buyXGetOne: 'buyXGetOneBackwards',
  buyXGetOnePercentageOff: 'buyXGetOnePercentageOffBackwards',
  buyXGetOneDollarOff: 'buyXGetOneDollarOffBackwards',
  buyXGetY: 'buyXGetYBackwards',
  buyXGetYPercentageOff: 'buyXGetYPercentageOffBackwards',
  buyXGetYDollarOff: 'buyXGetYDollarOffBackwards',
});

export const BACKWARDS_BOGO_SUBEXPERIENCES = Object.freeze([
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetOne,
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetDollarOff,
  BACKWARDS_BOGO_SUBEXPERIENCE_TAGS.buyOneGetPercentageOff,
]);

export const DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS = Object.freeze({
  buyMinAmountGetOne: 'buyMinAmountGetOne',
  buyMinAmountGetDollarOff: 'buyMinAmountGetDollarOff',
  buyMinAmountGetPercentageOff: 'buyMinAmountGetPercentageOff'
});

export const DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCES = Object.freeze(
  Object.values(DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCE_TAGS)
);

export const MSB_SUBEXPERIENCE_TAGS = Object.freeze({
  MSB1: 'MSB1',
  MSB2: 'MSB2',
  MSB3: 'MSB3',
  MSB4: 'MSB4',
  MSB5: 'MSB5',
  MSB6: 'MSB6',
});

export const MSB_SUBEXPERIENCES = Object.freeze(
  Object.values(MSB_SUBEXPERIENCE_TAGS)
);

export const BMSM_SUBEXPERIENCE_TAGS = Object.freeze({
  BMSM1: 'BMSM1',
  BMSM2: 'BMSM2',
  // There is no BMSM3
  BMSM4: 'BMSM4',
  BMSM5: 'BMSM5',
  BMSM6: 'BMSM6', // BMSM6 is suppressed to prevent bad actor abuse
  BMSM7: 'BMSM7',
});

export const BMSM_CATEGORIZED_SUBEXPERIENCE = Object.freeze({
  BMSM1Categorized: 'BMSM1Categorized',
  BMSM2Categorized: 'BMSM2Categorized',
  // There is no BMSM3Categorized
  BMSM4Categorized: 'BMSM4Categorized',
  BMSM5Categorized: 'BMSM5Categorized',
  BMSM6Categorized: 'BMSM6Categorized', // BMSM6Categorized is suppressed to prevent bad actor abuse
  BMSM7Categorized: 'BMSM7Categorized',
});

export const BMSM_SUBEXPERIENCES = Object.freeze(
  Object.values(BMSM_SUBEXPERIENCE_TAGS)
);

export const CATEGORIZED_SUBEXPERIENCES = Object.freeze(
  Object.values(BMSM_CATEGORIZED_SUBEXPERIENCE)
);

export const TWO_LIST_UI_SUBEXPERIENCES = Object.freeze([
  ...DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCES,
  'buyOneGetY',
  'buyOneGetYPercentageOff',
  'buyOneGetYDollarOff',
  'buyXGetOne',
  'buyXGetOnePercentageOff',
  'buyXGetOneDollarOff',
  'buyXGetY',
  'buyXGetYPercentageOff',
  'buyXGetYDollarOff',
  'buyOneGetYBackwards',
  'buyOneGetYPercentageOffBackwards',
  'buyOneGetYDollarOffBackwards',
  'buyXGetOneBackwards',
  'buyXGetOnePercentageOffBackwards',
  'buyXGetOneDollarOffBackwards',
  'buyXGetYBackwards',
  'buyXGetYPercentageOffBackwards',
  'buyXGetYDollarOffBackwards',
]);

export const PRODUCT_CTA_TYPE = Object.freeze({
  addToCart: 'addToCart',
  seeFullDetails: 'seeFullDetails',
  select: 'select',
});

export const FULFILLMENT_METHODS = Object.freeze({
  STH: 'ShipToHome',
  BOPIS: 'BOPIS',
  STORE: 'ShipToStore',
  BODFS: 'DeliverFromStore',
  APPLIANCE: 'DirectDelivery'
});

export const ABBREVIATED_FULFILLMENT_METHODS = Object.freeze({
  ShipToHome: 'sth',
  BOPIS: 'bopis',
  ShipToStore: 'boss',
  DeliverFromStore: 'express delivery',
  DirectDelivery: 'direct delivery',
});

export const DRAWER_TYPES = Object.freeze({
  PROMO_LIST_1_DRAWER: 'PROMO_LIST_1_DRAWER',
  PROMO_LIST_2_DRAWER: 'PROMO_LIST_2_DRAWER',
  PROMO_SUMMARY_DRAWER: 'PROMO_SUMMARY_DRAWER',
  QUICKVIEW_DRAWER: 'QUICKVIEW_DRAWER',
  QUICKVIEW_OVERVIEW_DRAWER: 'QUICKVIEW_OVERVIEW_DRAWER',
  QUICKVIEW_REVIEWS_DRAWER: 'QUICKVIEW_REVIEWS_DRAWER',
  QUICKVIEW_SPECS_DRAWER: 'QUICKVIEW_SPECS_DRAWER',
  WARNING_DRAWER: 'WARNING_DRAWER',
});

export const MESSAGE_KEY = Object.freeze({
  addToCartButton: 'addToCartButton',
  bogoQualifyingImageBottomText: 'bogoQualifyingImageBottomText',
  bogoQualifyingImagePlaceholderInnerText: 'bogoQualifyingImagePlaceholderInnerText',
  bogoRewardImageBottomText: 'bogoRewardImageBottomText',
  bogoRewardImagePlaceholderInnerText: 'bogoRewardImagePlaceholderInnerText',
  cardCtaExpDate: 'cardCtaExpDate',
  cardCtaHeaderText: 'cardCtaHeaderText',
  cardCtaLoadingText: 'cardCtaLoadingText',
  cardCtaProductImageListText: 'cardCtaProductImageListText',
  cardCtaShopOfferButton: 'cardCtaShopOfferButton',
  drawerSuccessText: 'drawerSuccessText',
  editRewardsText: 'editRewardsText',
  editQualifyText: 'editQualifyText',
  srcProducts: 'srcProducts',
  tgtProducts: 'tgtProducts',
  itemLimitTextInfo: 'itemLimitTextInfo',
  missingQualifyDrawer1TextWarning: 'missingQualifyDrawer1TextWarning',
  missingQualifyDrawer2TextWarning: 'missingQualifyDrawer2TextWarning',
  podCtaAlmostWarningMessage: 'podCtaAlmostWarningMessage',
  podCtaExpDate: 'podCtaExpDate',
  podCtaPrimaryMessage: 'podCtaPrimaryMessage',
  podCtaSecondaryMessage: 'podCtaSecondaryMessage',
  podCtaShopThisOffer: 'podCtaShopThisOffer',
  podCtaTertiaryMessage: 'podCtaTertiaryMessage',
  podCtaTierQualifyingTemplate: 'podCtaTierQualifyingTemplate',
  podCtaTierRewardTemplate: 'podCtaTierRewardTemplate',
  podCtaSuccessMessage: 'podCtaSuccessMessage',
  podCtaWarningMessage: 'podCtaWarningMessage',
  podCtaRewardWarningMessage: 'podCtaRewardWarningMessage',
  podCtaQualifyWarningMessage: 'podCtaQualifyWarningMessage',
  podCtaErrorMessage: 'podCtaErrorMessage',
  progressBarEndLabel: 'progressBarEndLabel',
  progressBarStartLabel: 'progressBarStartLabel',
  progressBarStatusText: 'progressBarStatusText',
  progressBarInfoText: 'progressBarInfoText',
  progressBarSuccessText: 'progressBarSuccessText',
  promoSummaryButtonTooltip: 'promoSummaryButtonTooltip',
  promoSummarySubtitle: 'promoSummarySubtitle',
  productCategoryLimits: 'productCategoryLimits',
  quickviewOverviewButton: 'quickviewOverviewButton',
  quickviewReviewsButton: 'quickviewReviewsButton',
  quickviewSpecsButton: 'quickviewSpecsButton',
  removeFromCartButton: 'removeFromCartButton',
  seeFullDetailsButton: 'seeFullDetailsButton',
  selectButton: 'selectButton',
  selectedItemInCartMessage: 'selectedItemInCartMessage',
  selectThisInsteadButton: 'selectThisInsteadButton',
  shopAllButton: 'shopAllButton',
  soldOutButton: 'soldOutButton',
  srcListFooterText: 'srcListFooterText',
  shopAllButtonCategory: 'shopAllButtonCategory',
  srcListSubtitle: 'srcListSubtitle',
  tgtListAlertText: 'tgtListAlertText',
  tgtListSubtitle: 'tgtListSubtitle',
  title: 'title',
  unselectButton: 'unselectButton',
  viewCartButton: 'viewCartButton',
  warningDrawerContinueWithoutButton: 'warningDrawerContinueWithoutButton',
  warningDrawerPrimaryMessage: 'warningDrawerPrimaryMessage',
  warningDrawerSecondaryMessage: 'warningDrawerSecondaryMessage',
  rewardButtonText: 'rewardButtonText',
});

export const MESSAGE_STATE = Object.freeze({
  anchorItemUnfulfillable: 'anchorItemUnfulfillable',
  src1ListUnfulfillable: 'src1ListUnfulfillable',
  success: 'success',
  almost: 'almost',
  initial: 'initial'
});

export const MESSAGE_PLURAL = Object.freeze({
  none: 'none', // exactly 0
  one: 'one', // exactly 1
  two: 'two', // exactly 2
  many: 'many', // more than 2
  other: 'other' // default if none others are true / defined
});

export const MESSAGE_NUMBER_WORD_MAP = Object.freeze({
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
});

export const MAX_NUM_TIERS_IN_PROMOTION = 5;
export const MULTI_SELECT_QUANTITY = 1;
export const MA = 'MAJOR_APPLIANCE';
export const HOME_DEPOT_QUOTE_CENTER = 'HDQC';
export const GM = 'MERCHANDISE';
export const NVALUE_REFINEMENT_SEPARATOR = 'Z';
export const NUM_PREVIEW_IMAGES = 5; // used for dollar threshold

export const ASSETS_IMAGE_URL = 'https://assets.thdstatic.com/images/v1/';
